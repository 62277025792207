import React from 'react'
import { connect } from 'redux-zero/react'
import actions from '../../store/actions'
import { getRetailConfig } from '../../services/api'

const withRetailConfig = WrappedComponent => {
  class WithRetailConfig extends React.Component {
    constructor(props) {
      super(props)
    }

    handleResponse = response => {
      if (response.status === 401) {
        this.props.logout()
      } else if (response.status === 200) {
        this.props.setRetailConfig(this.props.selected_rid, response.config)
        this.props.setAppTaskTypes(
          this.props.retailsConfig[this.props.selected_rid] &&
            this.props.retailsConfig[this.props.selected_rid]
              .dynamic_task_views !== undefined
            ? this.props.retailsConfig[this.props.selected_rid]
                .dynamic_task_views
            : []
        )
      }
    }

    _getRetailConfig = () => {
      this.props.showFullScreenLoader()
      getRetailConfig(
        this.handleResponse,
        this.props.selected_rid,
        this.props.hideFullScreenLoader
      )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.selected_rid !== this.props.selected_rid) {
        this._getRetailConfig()
      }
    }

    componentDidMount() {
      if (this.props.selected_rid !== null) {
        this._getRetailConfig()
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  const mapStateToProps = ({
    selected_rid,
    loading,
    retailsConfig,
    taskTypes,
    etl_actions,
    handleAlert,
    subRidIds,
    logout,
    retailTags,
  }) => ({
    selected_rid,
    loading,
    retailsConfig,
    taskTypes,
    etl_actions,
    handleAlert,
    subRidIds,
    logout,
    retailTags,
  })

  return connect(mapStateToProps, actions)(WithRetailConfig)
}

export default withRetailConfig
