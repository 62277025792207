import React, { useEffect, useState } from 'react'
import { Box, Checkbox } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'

export const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))

export const CustomizedMenus = ({
  subRidIds,
  selectedRid,
  selectedSubRidId,
  setSelectedSubRidId,
  checkedId,
  setChekedId,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    setChekedId(selectedSubRidId)
  }, [selectedSubRidId])
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleChange = id => {
    setChekedId(id)
    setSelectedSubRidId(id)
  }

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
        style={{ minWidth: '240px' }}
      >
        {checkedId}
      </Button>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {subRidIds.length > 0 ? (
          subRidIds.map(id => {
            return (
              <Box
                key={id}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <MenuItem disableRipple>
                  <ListItemText primary={id} />
                </MenuItem>
                <Checkbox
                  checked={id === checkedId}
                  onChange={() => handleChange(id)}
                  style={{ color: '#5c5cff' }}
                />
              </Box>
            )
          })
        ) : (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <MenuItem disableRipple>
              <ListItemText primary={selectedRid} />
            </MenuItem>
            <Checkbox checked={true} style={{ color: '#5c5cff' }} />
          </Box>
        )}
      </StyledMenu>
    </div>
  )
}
