import React from 'react'
import Layout from '../components/layout'
import FullScreenLoader from '../components/FullScreenLoader'
import { ETLList } from '../components/ETLS/ETLList'
import withRetailConfig from '../components/Elements/withRetailConfig'

const ETLSMain = ({
  loading,
  etl_actions,
  selected_rid,
  handleAlert,
  subRidIds,
  logout,
}) => {
  return (
    <Layout>
      <ETLList
        etlActions={etl_actions}
        selectedRid={selected_rid}
        handleAlert={handleAlert}
        logout={logout}
        subRidIds={subRidIds}
      />
      <FullScreenLoader loading={loading} />
    </Layout>
  )
}

export default withRetailConfig(ETLSMain)
