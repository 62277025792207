import {
  Box,
  IconButton,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import React, { useState } from 'react'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import GradeIcon from '@material-ui/icons/Grade'
import { useStyles } from './style'
import { sendETL } from '../../services/api'
import Keys from '../../utils/Keys.json'

export const ETLItem = ({
  etl,
  selectedSubRidId,
  setDisabled,
  disabled,
  handleAlert,
  logout,
}) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)

  const handleResponseError = status => {
    if (status === '401') {
      logout()
    }
  }

  const handleSubmissionResponse = response => {
    let msg
    let alertType
    if (response.status <= 300) {
      msg = 'Changes Were Saved :) !'
      alertType = Keys.ALERT_SUCCESS
      setLoading(false)
      setDisabled(false)
    }
    if (response.status > 300 || response.status === undefined) {
      msg = 'ERROR: changes were not saved, something is wrong with the data'
      alertType = Keys.ALERT_ERROR
      setLoading(false)
      setDisabled(false)
    }

    if (msg) {
      handleAlert({
        text: msg,
        type: alertType,
      })
    }

    handleResponseError(response.status)
  }

  const handleSendETL = etl => {
    const body = {
      action_id: etl,
      app_retail_id: selectedSubRidId,
    }
    sendETL(handleSubmissionResponse, body, setLoading, setDisabled)
  }

  return (
    <Box
      className={
        !disabled && !loading
          ? [classes.etlItemWrapper, classes.hoveredItem]
          : disabled && !loading
          ? [classes.etlItemWrapper]
          : [classes.etlItemWrapper, classes.activeItem]
      }
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: '10px',
          alignItems: 'center',
        }}
      >
        <GradeIcon
          style={{
            color: !disabled || (disabled && loading) ? '#f4c425' : 'gray',
          }}
        />
        <Typography style={{ fontSize: '22px' }}>{etl}</Typography>
      </Box>
      <IconButton
        disabled={disabled}
        disableRipple
        style={{
          outline: 'none',
          backgroundColor: 'transparent',
        }}
        onClick={() => handleSendETL(etl)}
      >
        {loading ? (
          <CircularProgress style={{ width: '40px', height: '40px' }} />
        ) : (
          <PlayCircleFilledIcon
            className={
              !disabled
                ? classes.playBtn
                : [classes.playBtn, classes.playBtnDisabled]
            }
          />
        )}
      </IconButton>
    </Box>
  )
}
