import { Box, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { CustomizedMenus } from './Menu'
import { ETLItem } from './ETLItem'

export const ETLList = ({
  etlActions,
  selectedRid,
  handleAlert,
  logout,
  subRidIds,
}) => {
  const [disabled, setDisabled] = useState(false)
  const [selectedSubRidId, setSelectedSubRidId] = useState(
    subRidIds?.length > 0 ? subRidIds[0] : []
  )
  const [checkedId, setChekedId] = useState()

  useEffect(() => {
    if (subRidIds && subRidIds.length > 0) {
      setChekedId(subRidIds[0])
      setSelectedSubRidId(subRidIds[0])
    } else {
      setChekedId(selectedRid)
      setSelectedSubRidId(selectedRid)
    }
  }, [subRidIds, selectedRid])

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        width="90%"
      >
        <Typography variant="h3">Operations</Typography>
        <CustomizedMenus
          subRidIds={subRidIds}
          selectedRid={selectedRid}
          setChekedId={setChekedId}
          checkedId={checkedId}
          selectedSubRidId={selectedSubRidId}
          setSelectedSubRidId={setSelectedSubRidId}
        />
      </Box>
      <Box
        style={{
          width: '90%',

          marginTop: '30px',
        }}
      >
        {etlActions?.map((etl, idx) => (
          <ETLItem
            key={idx}
            etl={etl}
            setDisabled={setDisabled}
            disabled={disabled}
            selectedRid={selectedRid}
            handleAlert={handleAlert}
            logout={logout}
            selectedSubRidId={selectedSubRidId}
          />
        ))}
      </Box>
    </>
  )
}
