import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  playBtn: {
    borderRadius: '50px',
    color: '#66739f',
    transition: '0.2s all',
    cursor: 'pointer',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 4px 1px',
    width: '40px',
    height: '40px',
    '&:active': {
      transform: 'scale(0.93)',
      boxShadow: 'none',
    },
  },
  playBtnDisabled: {
    color: 'gray',
    boxShadow: 'none',
    transform: 'scale(0.93)',
  },
  etlItemWrapper: {
    background: '#f4f6fa',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px',
    borderRadius: '20px',
    marginBottom: '20px',
    transition: 'transform 0.3s ease',
  },
  activeItem: {
    background: '#e9edff',
    transform: 'translateY(-5px)',
    boxShadow: ' 0px 5px 15px gray',
  },
  hoveredItem: {
    '&:hover': {
      background: '#e9edff',
      transform: 'translateY(-5px)',
      boxShadow: ' 0px 5px 15px gray',
    },
  },
}))
